import axios from 'axios'
import store from '../store'

export class Api {
  async put ({ url, request }) {
    try {
      const response = await axios.put(url, request)
      store.dispatch('showSnackBar', { text: response.data.message, color: response.data.success ? 'success' : 'error' })
      return response.data.success
    } catch (error) {
      store.dispatch('showSnackBar', { text: error, color: 'error' })
      return false
    }
  }

  async post ({ url, request }) {
    try {
      const response = await axios.post(url, request)
      store.dispatch('showSnackBar', { text: response.data.message, color: response.data.success ? 'success' : 'error' })
      return response.data.success
    } catch (error) {
      store.dispatch('showSnackBar', { text: error, color: 'error' })
      return false
    }
  }

  async delete (url) {
    try {
      const response = await axios.delete(url)
      store.dispatch('showSnackBar', { text: response.data.message, color: response.data.success ? 'success' : 'error' })
      return response.data.success
    } catch (error) {
      store.dispatch('showSnackBar', { text: error, color: 'error' })
      return false
    }
  }
}
