<template>
  <base-material-card
    image
    hover-reveal
    color="white"
    elevation="8"
    class="profile-section"
    width="128"
  >
    <input
      ref="file"
      type="file"
      class="d-none"
      @change="uploadPhoto"
    >
    <div
      class="status-badge"
      style="padding: 5px;"
    >
      <v-speed-dial direction="bottom">
        <template v-slot:activator>
          <v-btn
            fab
            small
            :loading="acting"
            color="accent"
          >
            <v-icon>
              mdi-chevron-down
            </v-icon>
          </v-btn>
        </template>
        <v-tooltip
          v-for="(action, j) in actions"
          :key="j"
          left
        >
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              v-bind="attrs"
              :color="action.color"
              fab
              small
              @click="trigger(action.action)"
              v-on="on"
            >
              <v-icon v-text="action.icon" />
            </v-btn>
          </template>

          <span>{{ action.what }}</span>
        </v-tooltip>
      </v-speed-dial>
    </div>
    <template v-slot:image>
      <!-- <v-img
        v-if="policy.profile_pic"
        :src="policy.profile_pic"
        class="profile-photo mx-auto"
      />
      <v-icon
        v-else
        size="100"
        color="grey"
      >
        mdi-shield-account
      </v-icon> -->
      <v-icon
        size="100"
        color="grey"
      >
        mdi-shield-account
      </v-icon>
    </template>

    <template
      v-slot:reveal-actions
    >
      <v-tooltip
        bottom
      >
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            text
            :loading="uploading"
            v-on="on"
            @click="$refs.file.click()"
          >
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
        </template>
        <span>Upload/Change Image</span>
      </v-tooltip>
    </template>

    <v-card-text class="text-h3 text-center">
      <router-link :to="clientLink">
        {{ clientName }}
      </router-link>

      <v-form
        ref="policyProfileForm"
        lazy-validation
        @submit.prevent="updateProfile"
      >
        <v-select
          v-model="policy.status_type_policy_id"
          label="Status"
          :loading="loadingMixins.policyStatuses"
          :items="mixinItems.policyStatuses"
          item-text="name"
          item-value="id"
        />
        <v-btn
          color="success"
          small
          type="submit"
          :loading="updating"
        >
          <v-icon left>
            mdi-update
          </v-icon>
          Update
        </v-btn>
      </v-form>
    </v-card-text>
  </base-material-card>
</template>

<script>
  import { Api } from '@/apis'
  import { fetchInitials } from '@/mixins/fetchInitials'
  import { MIXINS } from '@/constants'

  export default {
    mixins: [
      fetchInitials([
        MIXINS.policyStatuses,
      ]),
    ],

    props: {
      policy: {
        type: Object,
        default: () => ({}),
      },
    },

    data: () => ({
      acting: false,
      uploading: false,
      actions: [
        { icon: 'mdi-account-key-outline', color: 'success', what: 'Enable Client Access', action: 'enable_client_access' },
        { icon: 'mdi-plus', color: 'secondary', what: 'Add Task', action: 'add_task' },
        { icon: 'mdi-email-send-outline', color: 'warning', what: 'Send Email', action: 'send_email' },
        { icon: 'mdi-comment-account-outline', color: 'primary', what: 'Send Text', action: 'send_text' },
        { icon: 'mdi-television', color: 'info', what: 'Start Screen Share', action: 'start_screen_share' },
      ],
      updating: false,
    }),

    computed: {
      clientLink () {
        const user = this.policy.insured_user_id ? 1 : 0
        const id = this.policy.insured_user_id || this.policy.insured_company_id
        return `/clients/${user}-${id}`
      },
      clientName () {
        return this.policy.insured_user_id ? this.policy.insured_user.first_name + this.policy.insured_user.last_name : this.policy.insured_company.name
      },
    },

    methods: {
      async updateProfile () {
        if (this.$refs.policyProfileForm.validate()) {
          this.updating = true
          await new Api().put({
            url: 'policies/policy-status/' + this.$route.params.id,
            request: { status_type_policy_id: this.policy.status_type_policy_id },
          })
          this.updating = false
        }
      },

      uploadPhoto () {},

      trigger (action) {},
    },
  }
</script>
